.program-header{
    display: flex;
    font-size: 3rem;
    font-weight: bold;
    justify-content: space-around;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.plans-container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 4rem;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plan:nth-child(2){
    background-image: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    transform: scale(1.1);
}
.plan>svg {
    fill: var(--orange);
    height: 2rem;
    width: 2rem;
}
.plans>:nth-child(2)>svg {
    fill: #fff;
}

.plan>:nth-child(2) {
    font-size: 1.5rem;
    font-weight: 700;
}
.plan>:nth-child(3) {
    font-size: 3rem;
    font-weight: 700;
}
.plan>:nth-child(5) {
    font-size: .8rem;

}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>button {
    color: orange;
}
.feature{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.button-plan:nth-child(2){
    color:var(--orange) ;

}

/*  */
.plan:hover{
    background-image: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
    transform: scale(1.1);
}
.plans>:nth-child(1):hover>svg {
    fill: #fff;
}
.plans>:nth-child(3):hover>svg {
    fill: #fff;
}

.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    right: 0rem;
}