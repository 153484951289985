.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 15rem;
    /* gap: 5rem; */
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    text-transform: uppercase;
    font-style: italic;
    color: white;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    background-color: var(--gray);
    padding: 2rem;
    flex-direction: column;
    gap: 2rem;
    color: white;

    justify-content: space-between;
}


.category>:nth-child(1){
    fill: white;
    height: 2rem;
    width: 2rem;
}
.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.join-now>img{
    width: 1rem;
}
.category:hover{
    /* background-color: var(--nplanCard); */
    background-image: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
 box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
  cursor: pointer;
} 
