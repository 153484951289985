.Hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    flex-direction: column;
    gap: 2rem;
    display: flex;
}
.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.right-h>.btn {
    color: #000;
    position: absolute;
    right: 3rem;
    top: 2rem;
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 30px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    justify-content: flex-start;
}
.the-best-ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    top: 5px;
    z-index: 1;
}
.the-best-ad>span{
    z-index: 2;
}
.shape{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size:4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.shape>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 300;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
    color: var(--gray);
    font-size: 1rem;
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}
.hero-buttons>:nth-of-type(1){
    background-color: var(--orange);
    color: white;
    width: 8rem;
}
.hero-buttons>:nth-of-type(2){
    border: 1px solid var(--orange);
    background-color: transparent;
    color: white;
    width: 8rem;
}
.right-h>btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    background-color: var(--darkGrey);
    gap: 1rem;
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 5px;
    right: 4rem;
    top: 7rem;
    position: absolute;

}
/* .heart-rate>span:nth-of-type(2){
    color: var(--gray);
}
.heart-rate>span:nth-of-type(3){
    color: white;
    font-size: 14px;
} */
.heart-rate>:nth-child(2){
    color: var(--gray);
}
.heart-rate>:nth-child(3){
    color:white;
    font-size: 2rem;
}

.heart-rate>img{
    width: 1.5rem;
}
.hero-image{
    position: absolute;
    right: 8rem;
    top: 10rem;
    width: 23rem;
}
.hero-image-back{
    position:absolute;
    z-index: -1;
    top: 4rem;
    right: 20rem;
    width: 15rem;
}
.calories{
    display: flex;
    gap: 2rem;
    border-radius: 5px;
    background-color: var(--caloryCard);
    padding: 1rem;
    position: absolute;
    top: 34rem;
    width: 14rem;
    right: 35rem;
    z-index: 100;
}
.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
   flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
    color: var(--gray);
}
.calories>div>:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.hero-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}
@media screen and (max-width:768px){
    .Hero{
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
        
    }
    .shape{
        font-size:xx-large;
        align-items: center;
        justify-content: center;
    }
    .shape>div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
}

/* Galaxy Fold */
@media only screen and (max-width: 300px) and (min-width: 270px)  {

    .Hero{
        flex-direction: column;
    }
    .hero-blur{
        width: 14rem;
        padding: 0;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
        width: 16rem;
        
    }
    .shape{
        gap: 0.5rem;
        text-align: left;
        display: block;
        flex-direction: none;
    }
    .shape>div:nth-of-type(3){
        text-align: left;
    }
    .shape>div:nth-of-type(2)>span{
        display: flex;
        justify-content: flex-start;
    }
    
}

@media screen and ((max-width:1260px) and (min-width:1050px)) {
    .calories{top:40.3rem;}
}

@media screen and ((max-width:1049px) and (min-width:769px)) {
    .calories{top:43.5rem;}
}
