.Join{
    display: flex;
    padding:  0 2rem;
    gap: 10rem;
}
.left-join{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-join>hr{
position: absolute;
width: 10.5rem;
border: 2px solid var(--orange);
border-radius: 20%;
margin: -10px 0;
}
.left-join>div{
    display: flex;
    gap: 1rem;
}
.right-join{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    padding: 1rem 2rem ;
    gap: 3rem;
    background-color: var(--gray);
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background-color: var(--orange);
    color: white;
}