.Reasons
{
    padding: 0 2rem;
    display: flex;
    grid-template:2rem;
}
.left-image-side{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap :1rem;
    grid-auto-rows: 1fr;
}
.left-image-side>img{
    object-fit: cover;
}
.left-image-side>:nth-child(1){
    width: 15rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-image-side>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-image-side>:nth-child(3){
width: 15rem;
grid-column: 2/3;
grid-row: 2;
height: 11rem;

}
.left-image-side>:nth-child(4)
{
    width: 9rem;
    height: 11rem;
    grid-row: 2;
    grid-column: 3/4;
}
.right-side{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-side>span{
    color: var(--orange);
    font-size: 1rem;
    font-weight: bold;
}
.right-side>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.detail-reasons{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.detail-reasons>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
    align-items: center;
}
.detail-reasons>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2.5rem;
}